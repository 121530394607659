import { linkResolver, Subpath } from '../../utils/linkResolver';

const otherLocalized: any = {
  fr: 'Autre',
  en: 'Other',
  es: 'Otro',
};

const convertFooterSection = (section: any, locale: string) => {
  return section.items.map((item: any) => {
    return {
      title: item.title,
      url: item.internalLink
        ? linkResolver(item.internalLink, locale)
        : item.externalLink,
    };
  });
};

export const ourProductSectionGenerator = (
  companyBlock: any,
  softwareBlock: any,
  title: string,
  locale: string,
) => {
  return {
    title,
    categories: [
      {
        linksLists: [
          {
            title: companyBlock.title,
            links: convertFooterSection(companyBlock, locale),
          },
          {
            title: softwareBlock.title,
            links: convertFooterSection(softwareBlock, locale),
          },
        ],
      },
    ],
  };
};

export const learningHubSectionGenerator = (
  categories: any,
  subCategories: any,
  learningHubBlock: any,
  title: string,
  url: string,
  locale: string,
) => {
  const res: any = {
    title,
    url: `https://payfit.com${linkResolver({ slug: url }, locale, '')}`,
    categories: [],
  };

  categories.forEach((category: any) => {
    const subCatRelated = subCategories.filter(
      (subCat: any) => subCat.category.slug === category.slug,
    );
    res.categories.push({
      title: category.hero[0]?.title,
      url: linkResolver(
        {
          slug: category.slug,
        },
        locale,
        '',
        Subpath.BlogArticles,
      ),
      linksLists: subCatRelated.map((subCat: any) => {
        const blogArticleRelated = learningHubBlock.filter(
          (article: any) => article.subCategory?.slug === subCat.slug,
        );
        return {
          title: subCat.hero[0]?.title,
          url: linkResolver(
            {
              slug: subCat.slug,
            },
            locale,
            '',
            Subpath.BlogArticles,
          ),
          links: blogArticleRelated.map((article: any) => {
            return {
              title: article.metaFields.title,
              url: linkResolver(
                {
                  slug: article.slug,
                },
                locale,
                '',
                Subpath.BlogArticles,
              ),
            };
          }),
        };
      }),
    });
  });

  return res;
};

const getClientSectors = (datoClients: any, locale: string) => {
  const sectors: any = [];
  datoClients.map(
    (client: any) =>
      client.hero[0]?.sector?.clientSector &&
      !sectors.includes(client.hero[0]?.sector.clientSector) &&
      sectors.push(client.hero[0]?.sector.clientSector),
  );

  if (!sectors.length) sectors.push(otherLocalized[locale]); // adding a fallback sector

  return sectors;
};

const getClients = (datoClients: any, locale: any) => {
  const clients: any = [];
  datoClients.forEach((client: any) => {
    if (!client.slug) return;
    const clientInfo = client.hero[0];
    clients.push({
      title: clientInfo?.title,
      url: linkResolver(
        {
          slug: client.slug,
          trackingPageCategory: { title: 'client' },
        },
        locale,
        '',
        Subpath.Client,
      ),
      sector: clientInfo?.sector?.clientSector ?? otherLocalized[locale], // the fallback
    });
  });

  return clients;
};

export const clientSectionGenerator = (
  datoClients: any,
  title: string,
  url: string,
  locale: string,
) => {
  const sectors = getClientSectors(datoClients, locale);
  const clients = getClients(datoClients, locale);
  return {
    title,
    url: `https://payfit.com${linkResolver({ slug: url }, locale, '')}`,
    categories: [
      {
        linksLists: sectors.map((sector: any) => {
          const clientsRelated = clients.filter(
            (client: any) => client.sector === sector,
          );
          return {
            title: sector,
            links: clientsRelated.map((client: any) => {
              return {
                title: client.title,
                url: client.url,
              };
            }),
          };
        }),
      },
    ],
  };
};

export const resourceSectionGenerator = (
  sections: any,
  hubspotResources: any,
  url: string,
  title: string,
  locale: string,
) => {
  const resources = processResources(sections, hubspotResources);
  if (resources.length === 0) return { title, url, categories: [] };
  const res = {
    title,
    url: `https://payfit.com${linkResolver({ slug: url }, locale, '')}`,
    categories: [
      {
        linksLists: sections.map((section: any) => {
          const resourcesRelated = resources.filter(
            (resource: any) => resource.section === section.label,
          );
          if (resourcesRelated.length === 0) return { links: [] };
          return {
            title: section.label,
            links: resourcesRelated.map((resource: any) => {
              return {
                title: resource.title,
                url: resource.url,
              };
            }),
          };
        }),
      },
    ],
  };

  return res;
};

const processResources = (sections: any, hubspotResources: any) => {
  const resources: any = [];
  sections.forEach((section: any) => {
    const keywords = section.relatedKeywords.split(', ');

    keywords.forEach((keyword: string) => {
      const regEx = new RegExp(keyword, 'i');

      if (hubspotResources)
        hubspotResources.forEach((resource: any) => {
          if (
            resource?.node?.widgets?.desc?.body?.section &&
            resource.node.widgets.desc.body.section.match(regEx)?.length
          ) {
            const item = resource.node;
            resources.push({
              title: item.widgets.hero.body.title,
              url: item.url.endsWith('/') ? item.url : item.url.concat('/'),
              section: section.label,
            });
          }
        });
    });
  });

  return resources;
};
