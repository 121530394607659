import {
  textBody,
  textBodyLarge,
  textBodySmall,
} from '../../../../growth-platform-brand-system-v2/src/styles/typography';
import { classNames } from '../../../../growth-platform-brand-system-v2/src/utils/style';
import { ButtonTracking } from '../../../../growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import React from 'react';

export interface LinkProps {
  title: string;
  url: string;
}

export interface LinksListProps {
  title?: string;
  url?: string;
  links: LinkProps[];
}

export const LinksList = ({ title, url, links }: LinksListProps) => {
  return (
    <section className="LinksList py-12">
      {title && (
        <div className="pb-12 mb-12 border-b border-neutral-51 border-opacity-60">
          {url ? (
            <ButtonTracking
              component={'a'}
              section="LinksList_title"
              href={url}
              rel="noopener noreferrer"
              labelTracking={title}
            >
              <h4
                className={classNames(
                  textBodyLarge,
                  `${
                    url ? 'cursor-pointer hover:text-neutral-101' : ''
                  }  text-neutral-301`,
                )}
              >
                {title}
              </h4>
            </ButtonTracking>
          ) : (
            <h4
              className={classNames(
                textBodyLarge,
                `${
                  url ? 'cursor-pointer hover:text-neutral-101' : ''
                } text-neutral-301`,
              )}
            >
              {title}
            </h4>
          )}
        </div>
      )}
      <div className="">
        <ul>
          {links.map((link, i) => {
            return (
              <li
                className={classNames(textBody, `py-4 text-blue-101`)}
                key={i}
              >
                <ButtonTracking
                  component={'a'}
                  section={`LinksList_title_${title}`}
                  index={i}
                  className={classNames(
                    textBodySmall,
                    'cursor-pointer text-neutral-101 hover:text-neutral-301',
                  )}
                  rel="noopener noreferrer"
                  href={link.url}
                  labelTracking={link.title}
                >
                  {link.title}
                </ButtonTracking>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};
