import React from 'react';

import {
  textBody,
  textTitle2,
  textTitle3,
} from '../../../../growth-platform-brand-system-v2/src/styles/typography';
import { classNames } from '../../../../growth-platform-brand-system-v2/src/utils/style';
import { ButtonTracking } from '../../../../growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import { LinksList, LinksListProps } from '../../components/LinksList';

export interface CategoryProps {
  title: string;
  url?: string;
  linksLists: LinksListProps[];
}

export interface LinksBlockProps {
  title?: string;
  url?: string;
  categories: CategoryProps[];
}

export const LinksBlock = ({ title, url, categories }: LinksBlockProps) => {
  return (
    <section className="LinksBlock mt-24 pt-24">
      <div className="container">
        {title && (
          <div className="pb-12 mb-12">
            {url ? (
              <ButtonTracking
                component={'a'}
                section="LinksBlock_title"
                href={url}
                rel="noopener noreferrer"
                labelTracking={title}
              >
                <h2
                  className={classNames(
                    textTitle2,
                    'cursor-pointer hover:text-neutral-101 text-neutral-301',
                  )}
                >
                  {title}
                </h2>
              </ButtonTracking>
            ) : (
              <h2 className={classNames(textTitle2, 'text-neutral-301')}>
                {title}
              </h2>
            )}
          </div>
        )}
        <div className="">
          {categories.map((category, i) => {
            return (
              <div className={classNames(textBody, 'py-4 mb-40')} key={i}>
                {category.title ? (
                  category.url ? (
                    <ButtonTracking
                      component={'a'}
                      section="LinksBlock_category"
                      index={i}
                      rel="noopener noreferrer"
                      href={category.url}
                      labelTracking={category.title}
                    >
                      <h3
                        className={classNames(
                          textTitle3,
                          'cursor-pointer hover:text-neutral-101 text-neutral-301',
                        )}
                      >
                        {category.title}
                      </h3>
                    </ButtonTracking>
                  ) : (
                    <h3 className={classNames(textTitle3, 'text-neutral-301')}>
                      {category.title}
                    </h3>
                  )
                ) : (
                  ''
                )}
                <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-24">
                  {category.linksLists.map((linksList, linksListIndex) => (
                    <li key={linksListIndex} className="">
                      <LinksList
                        title={linksList?.title}
                        links={linksList?.links}
                        url={linksList?.url}
                        key={linksListIndex}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
